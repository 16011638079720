import { Button, Card, Fieldset, MultiSelect, Select } from "@mantine/core";
import { IconMinus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";


function SimIndividualRequest(props) {
  const [targetTrack, setTargetTrack] = useState(null);
  const [targetTrackBays, setTargetTrackBays] = useState(null);
  const [closedBays, setClosedBays] = useState([]);


  useEffect(() => {
    if (props.availableTargetTracks.includes(targetTrack) === false) {
      setTargetTrack(null);
    } else if (props.request.targetTrack !== targetTrack) {
      //this happens if a request is removed
      setTargetTrack(props.request.targetTrack);
      setClosedBays(props.request.closedBaysOnTargetTrack);
    }
  }, [props.availableTargetTracks,
  props.request.targetTrack,
  props.request.closedBaysOnTargetTrack,
    targetTrack])

  function setRequestTargetTrack(track) {
    setTargetTrack(track);

    //figure out what bays exist in the target track
    setTargetTrackBays(props.trackBays.find(x => x.trackId === track).availableBays);
    update(track, closedBays);
  }

  function setRequestClosedBays(bays) {
    setClosedBays(bays);
    update(targetTrack, bays);
  }

  function update(track, bays) {
    props.updateOrder(props.requestIndex, track, bays);
  }

  function removeOrderClicked(e) {
    props.removeOrder(props.requestIndex);
  }

  return (
    <Card>
      <Card.Section>
        <Fieldset legend={'Order ' + props.requestIndex}>
          <Select
            label='Target Track:'
            allowDeselect={false}
            required={true}
            data={props.availableTargetTracks}
            placeholder='Target Track'
            size='xs'
            value={targetTrack}
            onChange={setRequestTargetTrack}
          >
          </Select>
          <MultiSelect
            label='Closed Bays on Target Track:'
            data={targetTrackBays}
            placeholder='Bays'
            withScrollArea={false}
            size='xs'
            value={closedBays}
            onChange={setRequestClosedBays}
          >
          </MultiSelect>
          <Button
            mt={8}
            size='xs'
            leftSection={<IconMinus size={14} />}
            onClick={removeOrderClicked}
          >Remove This Order</Button>
        </Fieldset>
      </Card.Section>
    </Card >
  );
}

export default SimIndividualRequest;
