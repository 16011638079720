import { Button, Container, Group, Modal, ScrollArea, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus, IconRefresh } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import SimIndividualRequest from './sim-individual-request';


function SimMultiRequest(props) {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [selectedYardTracks, setSelectedYardTracks] = useState([]);
  const [individualRequests, setIndividualRequests] = useState([]);
  const [trackBays, setTrackBays] = useState([]);
  const [unusedRackTracks, setUnusedRackTracks] = useState([]);

  useEffect(() => {
    function getTracksWithRacks() {
      const keys = Object.keys(props.racks);
      let trackList = [];
      keys.forEach(rack => {
        trackList = trackList.concat(props.racks[rack].trackIds);
      });

      return trackList;
    }

    const tracksWithRacks = getTracksWithRacks();
    setUnusedRackTracks(tracksWithRacks);


    if (props.targetYard) {
      setSelectedYardTracks(props.yards[props.targetYard].trackIds.filter(x => tracksWithRacks.includes(x)));

      let newTrackBays = [];
      tracksWithRacks.forEach(track => {
        let bays = [];
        const foundRack = Object.values(props.racks).filter(x => x.trackIds.includes(track))?.[0];
        if (foundRack) {
          for (let i = 1; i <= Number(foundRack.racklength); i++) {
            bays.push(i.toString());
          }
          newTrackBays.push({ trackId: track, availableBays: bays });
        }
      });

      setTrackBays(newTrackBays);
    }




    //ensure there's at least one request ready
    if (individualRequests.length === 0) {
      const newRequest = {
        index: individualRequests.length + 1,
        targetTrack: '',
        closedBaysOnTargetTrack: [],
      };
      setIndividualRequests([...individualRequests, newRequest]);
    }

  }, [props.targetYard, props.racks, individualRequests, props.yards])


  function addOrderClicked(e) {
    const newRequest = {
      index: individualRequests.length + 1,
      targetTrack: '',
      closedBaysOnTargetTrack: [],
    };
    setIndividualRequests([...individualRequests, newRequest]);
  }

  function updateOrder(index, track, closedBays) {
    let updatedUnusedRackTracks = [...selectedYardTracks];
    const updatedIndividualRequests = individualRequests.map((req) => {
      if (req.index === index) {
        const updatedRequest = {
          index: index,
          targetTrack: track,
          closedBaysOnTargetTrack: closedBays,
        };
        updatedUnusedRackTracks = updatedUnusedRackTracks.filter(x => x !== track);
        return updatedRequest;
      } else {
        updatedUnusedRackTracks = updatedUnusedRackTracks.filter(x => x !== req.targetTrack);
        return req;
      }
    });
    setIndividualRequests(updatedIndividualRequests);
    setUnusedRackTracks(updatedUnusedRackTracks);
  }

  function removeOrder(index) {
    const updatedIndividualRequests = individualRequests.toSpliced(index - 1, 1);
    let i = 1;
    updatedIndividualRequests.forEach(req => {
      req.index = i;
      i += 1;
    });
    if (updatedIndividualRequests.length === 0) {
      const newRequest = {
        index: 1,
        targetTrack: '',
        closedBaysOnTargetTrack: [],
      };
      updatedIndividualRequests.push(newRequest);
    }
    setIndividualRequests(updatedIndividualRequests);
  }


  async function requestSimulation() {
    await props.requestSim(individualRequests);
    closeModal();
  }


  return (
    <Container>
      <Modal opened={modalOpened} onClose={closeModal} title='Create Multiple Requests'>
        <ScrollArea mt={16}>
          <Stack>
            {individualRequests?.map((request) => {
              return <SimIndividualRequest
                key={request.index}
                requestIndex={request.index}
                request={request}
                availableTargetTracks={selectedYardTracks}
                trackBays={trackBays}
                updateOrder={updateOrder}
                removeOrder={removeOrder}
                unusedTracks={unusedRackTracks}
              ></SimIndividualRequest>
            })}
          </Stack>
        </ScrollArea>
        <Group mt={8} justify='space-between'>
          <Button
            size='xs'
            fullWidth={false}
            leftSection={<IconPlus size={14} />}
            onClick={addOrderClicked}
          >Add Order...</Button>

          <Button
            size='xs'
            fullWidth={false}
            leftSection={<IconRefresh size={14} />}
            onClick={requestSimulation}
          >Request Simulation</Button>
        </Group>
      </Modal>

      <Button size='xs'
        onClick={openModal}
        disabled={props.targetYard === null}
        loading={props.isSimulatorRunning}
      >Create Request(s)</Button>
    </Container>
  );
}

export default SimMultiRequest;