import React from 'react';
import { Button, Divider, Grid, Image, Modal, Stack, Text } from '@mantine/core';
import styled from 'styled-components';
import { useDisclosure } from '@mantine/hooks';


const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: ruby;
`;

function getInfoLayout(props) {
  const watchCars = [];
  Object.values(props.cars)?.filter(x => x.watch === true).map((car) => {
    watchCars.push(car.id);
    return watchCars;
  });

  const watchCarLocations = [];
  Object.values(props.tracks).forEach((track) => {
    if (watchCars.some(x => track.carIds.includes(x))) {
      let info = {
        track: track.id,
        cars: track.carIds.filter(x => watchCars.includes(x)),
      };
      watchCarLocations.push(info);
    }
  });

  return watchCarLocations;
}

function FlaggedCars(props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Container>
      {Object.values(props.cars)?.filter(x => x.watch === true).length > 0
        ? <Container>
          <Image src='Flag.png' w={7} h={11} mr={4} display={'inline'} />
          Flagged Cars: {Object.values(props.cars)?.filter(x => x.watch === true).length.toString()}
          <Modal
            opened={opened}
            onClose={close}
            title={<div><Image src='Flag.png' w={7} h={11} mr={8} display={'inline'} />Flagged Cars</div>}
          >
            <Stack>
              <Text size='xs'>Cars that have unstandardized information associated with them from the uploaded
                35 Yard file.  If changes are needed, adjust the car information in the 35 Yard file and re-upload.</Text>
              <Divider />
              {getInfoLayout(props).map((info) => {
                return <div key={info.track + 'div'}>
                  <Text size='xs' fw='bold' c='#333333' key={info.track}>{info.track}</Text>
                  <Divider />
                  {
                    info.cars.map((car) => {
                      return <Grid key={car}>
                        <Grid.Col span={4}>
                          <Text size='sm'>{car}</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <Text size='xs'>{props.cars[car].disposition}</Text>
                        </Grid.Col>
                      </Grid>
                    })
                  }
                </div>
              })}

            </Stack>
          </Modal>
          <Button variant='subtle' p={4} size='xs' onClick={open}>See List</Button>
        </Container>
        : null}
    </Container>
  );
}

export default FlaggedCars;
