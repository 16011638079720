import { ActionIcon, Group, Text } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';


function ZoomControl(props) {
  const maxZoom = 200;
  const minZoom = 10;
  const zoomStep = 10;

  const [zoom, setZoom] = useState(100);

  return (
    <Group gap={4}>
      <Text size='xs' >Display:</Text>
      <Text fw={'bold'} size='xs'>{zoom}%</Text>
      <ActionIcon
        onClick={() => {
          const newZoom = zoom - zoomStep;
          setZoom(newZoom); props.zoomUpdated(newZoom)
        }}
        variant='default'
        size='xs'
        disabled={zoom <= minZoom}>
        <IconMinus style={{ margin: '0', width: '70%', height: '70%' }} stroke={2}></IconMinus>
      </ActionIcon>
      <ActionIcon
        onClick={() => {
          const newZoom = zoom + zoomStep;
          setZoom(newZoom);
          props.zoomUpdated(newZoom)
        }}
        variant='default'
        size='xs'
        disabled={zoom >= maxZoom}>
        <IconPlus style={{ margin: '0', width: '70%', height: '70%' }} stroke={2}></IconPlus>
      </ActionIcon>

    </Group>
  );
}

export default ZoomControl;