import React from 'react';
import styled from 'styled-components';
import { DragDropContext } from '@hello-pangea/dnd';
// import SimTrack from './sim-track';
import Track from './track';
import { getMoveSequence } from './sim-steps-list';
import { SimStepsListDisplay } from './sim-steps-list-display';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { ActionIcon, Button, Checkbox, Divider, Grid, Group, SegmentedControl, Stack, Stepper, Text } from '@mantine/core';
import { IconMathGreater, IconMathLower, IconPrinter } from '@tabler/icons-react';
import ZoomControl from './zoom-control';

const Container = styled.div`
  margin: 8px;
  border-radius: 2px;
  min-height: 70px;

  display: ${props => props.$selectedSimResult >= 0 ? 'block' : 'none'};
  flex-direction: column;
  background-color: white;
`;

const PlaybackContainer = styled.div`
  display: ${props => props.$displayMode === 'TRACK VIEW' ? 'block' : 'none'};
`;

const StepsListContainer = styled.div`
  display: ${props => props.$displayMode === 'MOVES VIEW' ? 'block' : 'none'};
`;

const YardWrapper = styled.div`
padding: 8px;
border: 1px solid black;
background-color: white;
display: ${props => props.$isVisible ? 'block' : 'none'};
`;

const YardTitle = styled.h3`
`;

const ZoomWrapper = styled.div`
  display: inline-flex;
  margin-right: 0px;
  zoom: ${props => props.$zoomLevel}%;
`;


const SimSize = styled.div`
  zoom: ${props => props.$zoomLevel}%;
`;

const TextSeparator = styled.div`
  display: inline;
  margin: 0 8px 0 8px;
`;

export default class SimPlayer extends React.Component {

    componentDidMount() {
        const newState = {
            ...this.state,
            showCarDetails: false,
            zoomLevel: 100,
            // simStepsZoomLevel: 0.5,
            displayMode: 'MOVES VIEW',
            moveSequence: [],
            showStepsInitialFinalConfig: false,
            yards: structuredClone(this.props.allYards),
            onlyDisplayAffectedTracks: true,
        };
        this.setState(newState);
        this.initializeSimTracks();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedSimResult !== this.props.selectedSimResult ||
            prevProps.simResults !== this.props.simResults
        ) {
            // console.log('sim-player - selectedSimResult changed from ' + prevProps.selectedSimResult + ' to ' + this.props.selectedSimResult);
            this.initializeSimTracks();
        }
    };

    initializeSimTracks = () => {
        let newState = this.initializeCarHighlighting();
        const seq = getMoveSequence(this.props.simResults, this.props.selectedSimResult, this.props.allCars, this.props.initialTrackLayout, this.props.yardEngineIds);
        newState = {
            ...newState,
            currentTrackLayout: structuredClone(this.props.initialTrackLayout),
            currentCars: structuredClone(this.props.allCars),
            currentStepNumber: 0,
            currentStepMessage: seq.calculatedSteps?.length > 0 ? seq.calculatedSteps[0].stepText : '',
            moveSequence: seq?.calculatedSteps ?? [],
            affectedTracks: seq.affectedTracks,
            nextDisabled: false,
            prevDisabled: true,
        };
        this.setState(newState);
    };

    initializeCarHighlighting = () => {
        if (this.state) {
            //remove highlighting from previous step
            let newState = {
                ...this.state,
            };

            const previouslyAddingToEngineCars = Object.values(this.state.currentCars).filter(x => x.isAddingToEngine);
            previouslyAddingToEngineCars.forEach(car => {
                car.isAddingToEngine = false;
                newState = {
                    ...newState,
                    [car.id]: car,
                };
            });
            const previouslyRemovingFromEngineCars = Object.values(this.state.currentCars).filter(x => x.isRemovingFromEngine);
            previouslyRemovingFromEngineCars.forEach(car => {
                car.isRemovingFromEngine = false;
                newState = {
                    ...newState,
                    [car.id]: car,
                };
            });

            // this.setState(newState);
            return newState;
        }
    }

    toggleShowCarDetails = () => {
        const newState = {
            ...this.state,
            showCarDetails: !this.state.showCarDetails,
        }
        this.setState(newState);
        // console.log(this.state.showCarDetails);
    };


    setCurrentStep = (e) => {
        this.setStepNumber(e + 1);
    }

    setStepNumber = (stepNumber) => {
        // console.log('e', e);

        const newState = {
            ...this.state,
            currentStepNumber: stepNumber,
            currentStepMessage: this.state?.moveSequence[stepNumber].stepText,
            nextDisabled: stepNumber >= this.state?.moveSequence.length - 1,  //the Start step is included
            prevDisabled: stepNumber < 1,
        }
        this.setState(newState);
    };

    nextClicked = () => {
        this.setStepNumber(this.state.currentStepNumber + 1);
    }

    prevClicked = () => {
        this.setStepNumber(this.state.currentStepNumber - 1);
    }

    zoomChange = (zoom) => {
        // console.log(e);
        const newState = {
            ...this.state,
            zoomLevel: zoom,
        }
        this.setState(newState);
    }

    // simStepsZoomChange = (e) => {
    //     // console.log(e);
    //     const newState = {
    //         ...this.state,
    //         simStepsZoomLevel: e.target.valueAsNumber,
    //     }
    //     this.setState(newState);
    // }

    rbSelected = (e) => {
        const newState = {
            ...this.state,
            displayMode: e,
        };
        this.setState(newState);
    }

    showStepsInitialFinal = (e) => {
        const newState = {
            ...this.state,
            showStepsInitialFinalConfig: !this.state.showStepsInitialFinalConfig,
        };
        this.setState(newState);
    }


    yardCheckChanged = (e) => {
        const yardUpdate = this.state?.yards[e.target.name];
        yardUpdate.visible = !yardUpdate.visible;

        const newState = {
            ...this.state,
            yards: {
                ...this.state.yards,
                [yardUpdate.id]: yardUpdate,
            }
        };

        this.setState(newState);
    }

    onlyDisplayAffectedTracks = () => {
        const newState = {
            ...this.state,
            onlyDisplayAffectedTracks: !this.state.onlyDisplayAffectedTracks,
        };
        this.setState(newState);
    }

    render() {
        return (
            <Stack>
                <Divider m='16 0 0 0' />
                <Grid>
                    <Grid.Col span={3}>
                        <SegmentedControl color='main-blue.1' data={['MOVES VIEW', 'TRACK VIEW']} value={this.state?.displayMode} onChange={this.rbSelected}></SegmentedControl>
                    </Grid.Col>
                    <Grid.Col span={3} offset={6} ta={'right'}>
                        <ReactToPrint
                            content={() => this.componentRef}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button bg='white'
                                        bd='1px solid main-blue'
                                        c='main-blue'
                                        leftSection={<IconPrinter size={14} />}
                                        onClick={handlePrint}
                                    >Print</Button>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </Grid.Col>
                </Grid>
                <Divider />
                <Grid grow={true} align='center'>
                    <Grid.Col span={10}>
                        <Group p={12} gap={32}>
                            <Checkbox label='Yard A North' name='Yard A North' checked={this.state?.yards['Yard A North']?.visible ?? false} onChange={this.yardCheckChanged} size='xs' />
                            <Checkbox label='Yard A South' name='Yard A South' checked={this.state?.yards['Yard A South']?.visible ?? false} onChange={this.yardCheckChanged} size='xs' />
                            <Checkbox label='Yard B' name='Yard B' checked={this.state?.yards['Yard B']?.visible ?? false} onChange={this.yardCheckChanged} size='xs' />
                            <Checkbox label='Yard C' name='Yard C' checked={this.state?.yards['Yard C']?.visible ?? false} onChange={this.yardCheckChanged} size='xs' />
                            <Checkbox label='Only Display Affected Tracks' name='AffectedTracks' checked={this.state?.onlyDisplayAffectedTracks ?? true} onChange={this.onlyDisplayAffectedTracks} size='xs' />
                            {this.state?.displayMode === 'MOVES VIEW'
                                ? <Checkbox label='Show Initial/Final Yard Configuration' size='xs'
                                    labelPosition='right'
                                    checked={this.state?.showStepsInitialFinalConfig ?? false}
                                    onChange={this.showStepsInitialFinal}
                                    display={'inline-flex'}>
                                </Checkbox>
                                : null}
                        </Group>
                    </Grid.Col>
                    <Grid.Col span='content'>

                        <ZoomWrapper>
                            {/* Zoom Level<input id="sim-steps-range" value={this.state?.simStepsZoomLevel ?? 1} type="range" step="0.1" min="0.1" max="1" onChange={this.simStepsZoomChange} /> */}
                            <ZoomControl zoomUpdated={this.zoomChange} />
                        </ZoomWrapper>
                    </Grid.Col>
                </Grid>

                <Divider />
                <Container
                    $selectedSimResult={this.props.selectedSimResult ?? null}
                >
                    {this.state?.moveSequence
                        ? <DragDropContext>
                            <PlaybackContainer $displayMode={this.state?.displayMode ?? 'MOVES VIEW'}>
                                <Stepper size='xs' onStepClick={this.setCurrentStep} active={this.state?.currentStepNumber}>
                                    {
                                        this.state?.moveSequence?.filter(x => x.id < this.state?.moveSequence.length - 1).map((move) => {
                                            return <Stepper.Step
                                                key={move.id}
                                                step={move.id}
                                            >
                                            </Stepper.Step>
                                        })}
                                </Stepper>
                                <TextSeparator />
                                <Group>
                                    <ActionIcon variant='default' size='sm'
                                        onClick={this.prevClicked}
                                        disabled={this.state?.prevDisabled ?? false}>
                                        <IconMathLower />
                                    </ActionIcon>
                                    <Text miw={50} fw='bold' align='center' size='sm'>
                                        {this.state?.currentStepNumber === this.state?.moveSequence.length - 1
                                            ? 'Final'
                                            : this.state?.currentStepNumber === 0
                                                ? 'Initial'
                                                : 'Move ' + this.state?.currentStepNumber ?? "Initial"
                                        }
                                    </Text>
                                    <ActionIcon variant='default' size='sm'
                                        onClick={this.nextClicked}
                                        disabled={this.state?.nextDisabled ?? false}>
                                        <IconMathGreater />
                                    </ActionIcon>
                                    <Text fw='bold' size='sm'>{this.state?.currentStepMessage ?? ''}</Text>
                                </Group>
                                <TextSeparator />
                                <SimSize $zoomLevel={this.state?.zoomLevel ?? 100}>
                                    {Object.values(this.state?.yards).map((yard) => {
                                        return <YardWrapper
                                            key={yard.id}
                                            $isVisible={yard.visible}>
                                            <YardTitle>{yard.id}</YardTitle>
                                            {yard.trackIds.map((trackId) => {
                                                const currStep = this.state?.moveSequence[this.state?.currentStepNumber ?? 0];
                                                if (currStep) {
                                                    const track = currStep.tracks[trackId];
                                                    if (track) {
                                                        if (!this.state?.onlyDisplayAffectedTracks || this.state?.affectedTracks.includes(trackId)) {
                                                            const cars = track.carIds.map(carId => currStep.cars[carId]);
                                                            // return <SimTrack
                                                            return <Track
                                                                key={track.id}
                                                                track={track}
                                                                cars={cars}
                                                                showCarDetails={this.showCarDetails}
                                                                racks={this.props.racks}
                                                                disableDragDrop={true}
                                                                isDisabledTrack={this.props.simResults?.disabledTracks.includes(track.simulator_id)}
                                                            />
                                                        }
                                                    }
                                                }
                                                return null;
                                            }
                                            )}
                                        </YardWrapper>
                                    })}
                                </SimSize>
                            </PlaybackContainer>

                            <StepsListContainer
                                $displayMode={this.state?.displayMode ?? 'MOVES VIEW'}
                                $selectedSimResult={this.props.selectedSimResult ?? null}
                            >
                                <ZoomWrapper $zoomLevel={this.state?.zoomLevel ?? 100}>
                                    <SimStepsListDisplay
                                        showStepsInitialFinalConfig={this.state?.showStepsInitialFinalConfig ?? false}
                                        onlyDisplayAffectedTracks={this.state?.onlyDisplayAffectedTracks ?? false}
                                        affectedTracks={this.state?.affectedTracks ?? []}
                                        moveSequence={this.state?.moveSequence ?? null}
                                        // simStepsZoomLevel={this.state?.zoomLevel ?? 100}
                                        allYards={this.state?.yards}
                                        racks={this.props.racks}
                                    // ref={el => (this.componentRef = el)}
                                    >
                                    </SimStepsListDisplay>
                                </ZoomWrapper>
                            </StepsListContainer>
                        </DragDropContext>
                        : null}
                </Container>
            </Stack>
        );
    }
}