import React from "react";
import styled from "styled-components";
import { Draggable } from "@hello-pangea/dnd";
import {
  Group,
  Stack,
  Text,
  Paper,
  Image,
  HoverCard,
  Indicator,
} from "@mantine/core";
import { IconSunFilled, IconMoonFilled } from "@tabler/icons-react";

const ItemContainer = styled.div`
  //   border: ${(props) =>
    props.$isSelected
      ? "2px solid black"
      : "1px solid " + props.$destRackColor};
  //   ${(props) =>
    props.$destInRack === true
      ? "border-left: 4px solid " + props.$destRackColor
      : ""};

  //   border-radius: 2px;
  //   padding: 6px 2px 6px 2px;
  margin-right: 8px;
  //   background-color: ${(props) =>
    props.$isDragging
      ? "lightgreen"
      : "white"}; //: props.isCN ? 'pink' : 'white'};
  width: 110px;

  display: block;
  justify-content: center;
  align-items: center;

  border: ${(props) =>
    props.$isAddingToEngine
      ? props.$isOnEngine
        ? "3px solid #37A80F"
        : "3px solid #F17D00"
      : props.$isRemovingFromEngine
      ? props.$isOnEngine
        ? "3px solid #F17D00"
        : "3px solid #37A80F"
      : "white"};
`;

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

export default class Car extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      destRackNumber: null,
      destRackColor: "lightgray",
      destInRack: false,
      isCN: false,
      isCP: false,
    };
  }

  componentDidMount() {
    const trackId = `Track ${this.props.car.track_1[0].replaceAll("'", "")}`;
    const rack = Object.values(this.props.racks).find((x) =>
      x.trackIds.includes(trackId)
    );

    let inRack = false;
    if (this.props.car.slot_1.length > 0 && rack) {
      const slots = this.props.car.slot_1[0]
        .replaceAll("'", "")
        .replaceAll(" ", "")
        .split(",");
      if (slots.length > 0) {
        try {
          inRack = slots.some((x) => parseInt(x) <= parseInt(rack.racklength));
        } catch {
          //ignore
        }
      }
    }

    const cnDisps = ["CN", "CNW", "CNE"];
    let regex = new RegExp(cnDisps.join("|"), "i");
    let isCN = regex.test(this.props.car.disposition_detail);

    const cpDisps = ["CP", "CPR", "CP RAIL"];
    regex = new RegExp(cpDisps.join("|"), "i");
    let isCP = regex.test(this.props.car.disposition_detail);

    let newState = {
      ...this.state,
      isCN: isCN,
      isCP: isCP,
    };

    if (rack) {
      newState = {
        ...newState,
        destRackNumber: rack.number,
        destRackColor: rack.color,
        destInRack: inRack,
      };
    }

    this.setState(newState);
  }

  onClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    if (event.button !== primaryButton) {
      return;
    }
    //marking the event as used
    event.preventDefault();

    this.performAction(event);
  };

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey;

  performAction = (event) => {
    const newCar = this.props.car;
    newCar.isSelected = !newCar.isSelected;

    const newState = {
      ...this.state,
      car: newCar,
    };

    this.setState(newState);
  };

  render() {
    return (
      <Draggable
        draggableId={this.props.car.id}
        index={this.props.index}
        isDragDisabled={
          this.props.disableDragDrop ||
          String(this.props.car.disposition ?? "")
            .toLowerCase()
            .includes("leave")
        }
      >
        {(provided, snapshot) => (
          <ItemContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            $isSelected={this.props.car.isSelected}
            $isDragging={snapshot.isDragging}
            onClick={this.onClick}
            $destRackColor={this.state.destRackColor}
            $destInRack={this.state.destInRack}
            $isAddingToEngine={this.props.car.isAddingToEngine}
            $isRemovingFromEngine={this.props.car.isRemovingFromEngine}
            $isOnEngine={this.props.isOnEngine}
          >
            {/* {console.log('leave?', this.props.car.disposition.toLowerCase().includes('leave'))} */}
            <Indicator
              position="top-end"
              color="main-blue.2"
              offset={6}
              size={10}
              disabled={this.props.car.isSpotted === false}
            >
              <Paper
                shadow="xs"
                radius="xs"
                p="xs"
                bg={
                  this.props.car.disposition.toLowerCase().includes("leave")
                    ? "#d4d4d4"
                    : "white"
                }
              >
                <Stack align="flex-start" gap={0}>
                  <Group gap={0}>
                    {this.props.car.watch === true ? (
                      <HoverCard>
                        <HoverCard.Target>
                          <Image
                            src="Flag.png"
                            w={7}
                            h={11}
                            mr={4}
                            display={"inline"}
                          />
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="sm">{this.props.car.disposition}</Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    ) : null}
                    <Text size="xs" fw={700}>
                      {this.props.car.id}
                    </Text>
                  </Group>
                  <Group gap={0}>
                    {this.props.car.le === "E" ||
                    this.props.car.le === "pL" ||
                    this.props.car.le === "L" ? (
                      <Text
                        size="xs"
                        bg={
                          this.props.car.le === "E"
                            ? "#333333"
                            : this.props.car.le === "pL"
                            ? "main-blue.6"
                            : "main-blue.8"
                        }
                        c={this.props.car.le === "L" ? "#333333" : "white"}
                        fw={500}
                        p="1px 4px 1px 4px"
                      >
                        {this.props.car.le}
                      </Text>
                    ) : null}
                    {this.state?.destRackNumber ? (
                      <Text
                        size="xs"
                        bg="white"
                        c="#333333"
                        bd="1px solid #333333"
                        fw={500}
                        p="0px 4px 0px 4px"
                      >
                        {this.state?.destRackNumber ?? ""}
                      </Text>
                    ) : (
                      <Text size="xs">&nbsp;</Text>
                    )}
                    {this.state?.isCN ? (
                      <Group
                        ml={8}
                        bg="#00306C"
                        gap={1}
                        justify="flex-end"
                        p="1px 3px 1px 3px"
                      >
                        <IconMoonFilled size={12} color="white" />
                        <Text
                          display="inline-flex"
                          size="xs"
                          c="white"
                          fw={500}
                        >
                          CN
                        </Text>
                      </Group>
                    ) : null}
                    {this.state?.isCP ? (
                      <Group
                        ml={8}
                        bg="#F1F2F2"
                        gap={1}
                        justify="flex-end"
                        p="1px 3px 1px 3px"
                      >
                        <IconSunFilled size={12} color="#2469BD" />
                        <Text
                          display="inline-flex"
                          size="xs"
                          c="#2469BD"
                          fw={500}
                        >
                          CP
                        </Text>
                      </Group>
                    ) : null}
                  </Group>
                </Stack>
              </Paper>
            </Indicator>
          </ItemContainer>
        )}
      </Draggable>
    );
  }
}
