import React from 'react';
import styled from 'styled-components';
import { Droppable } from '@hello-pangea/dnd';
import Car from './car';
import { HoverCard, Image, Text, Group } from '@mantine/core';

const Container = styled.div`
  margin: 4px;
  min-height: 60px;

  display: flex;
  flex-direction: column;
  width: 99%;
  background-color: ${props => (props.$isEngine ? '#D4D4D4' : '#F1F2F2')};
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;
const Title = styled.h5`
  padding-left: 8px;
  width: ${props => Math.max((props.$carLength * 130), 200)}px;
  display: inline;
`;
const RackTitle = styled.div`
  display: ${props => props.$rackSize > 0 ? 'inline' : 'none'};
  margin-left: 20px;
`;

const Capacity = styled.div`
  font-size: 12px;
  display: inline;
  margin-left: 8px;
//   background-color: ${props => (props.$carIdLength > props.$carLength ? 'red' : 'transparent')};
//   color: ${props => (props.$carIdLength > props.$carLength ? 'white' : 'black')};
`;

const RackCapacity = styled.div`
  font-size: 12px;
  display: inline;
  margin-left: 8px;
`;

const CarList = styled.div`
  padding: 8px;
  font-size: 12px;
  background-color: ${props => (props.$isDraggingOver ? 'skyblue' : 'transparent')};
  display: flex;
`;

const CapacityHighlight = styled.div`
  display: inline;
  font-size: 12px;
  color: ${props => (props.$overCapacity ? '#D02030' : 'black')};
  font-weight: ${props => (props.$overCapacity ? 'bold' : 'normal')};
`;

const Empty = styled.div`
  margin: 8px;
  border: 1px solid #A6A6A6;
  width: 100%;
  text-align: center;
  color: #A6A6A6;
`;

export default class Track extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rackName: '',
            rackColor: 'lightgray',
            rackSize: 0,
        };

        // console.log('track props', this.props);
    };

    componentDidMount() {
        const rack = Object.values(this.props.racks).find(x => x.trackIds.includes(this.props.track.id));

        if (rack) {
            const newState = {
                ...this.state,
                rackName: rack.id,
                rackColor: rack.color,
                rackSize: rack.racklength,
                isDisabledTrack: this.props.disabledTracks?.includes(this.props.track.simulator_id) ?? false,
            }
            this.setState(newState);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.disabledTracks) !== JSON.stringify(this.props.disabledTracks)
            || JSON.stringify(prevProps.disabledBays) !== JSON.stringify(this.props.disabledBays)) {
            //disabled tracks and/or bays have been changed
            const newState = {
                ...this.state,
                isDisabledTrack: this.props.disabledTracks?.includes(this.props.track.simulator_id) ?? false,
            };
            this.setState(newState);
            // console.log('sim id', this.props.track.simulator_id, 'newState', newState);
        }
    }

    render() {
        return (
            <Container
                $carLength={this.props.track.carlength}
                $carIds={this.props.track.carIds}
                backgroundcolor={this.props.track.backgroundcolor}
                $isEngine={this.props.track.isEngine}
                $rackColor={this.state.rackColor}
            >
                <Title $carLength={this.props.track.carlength}>

                    {/* disableDragDrop is set for results, and not for the yard editor */}
                    {this.state?.isDisabledTrack === true && this.props.disableDragDrop
                        ? <HoverCard>
                            <HoverCard.Target>
                                <Image src='/ErrorNote.png' w={15} h={16} display={'inline'} mr={4} align='center' />
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                                <Group>
                                    <Text fw={'bold'} size='xs'>Closed:</Text>
                                    <Text size='xs'>{this.props.track.id}</Text>
                                </Group>
                                {/* {this.state?.rackSize > 0
                                    ? <Group>
                                        <Text fw={'bold'} size='xs'>Rack???:</Text>
                                        <Text size='xs'>todo: find closed bays</Text>
                                    </Group>
                                    : null} */}
                            </HoverCard.Dropdown>
                        </HoverCard>
                        : null}
                    {this.props.track.title}
                    <Capacity
                        $carLength={this.props.track.carlength}
                        $carIdLength={this.props.track.carIds.length}
                    >Capacity:
                        <CapacityHighlight
                            $overCapacity={this.props.track.carIds.length > this.props.track.carlength}
                        >{this.props.track.carIds.length}
                        </CapacityHighlight>/{this.props.track.carlength}
                    </Capacity>

                    <RackTitle
                        $rackSize={this.state.rackSize}
                    >
                        {this.state.rackName}
                        <RackCapacity>
                            Capacity: {this.state.rackSize}
                        </RackCapacity>
                    </RackTitle>

                </Title>
                <Droppable
                    droppableId={this.props.track.id}
                    direction='horizontal'
                    isDropDisabled={this.props.disableDragDrop}
                >
                    {(provided, snapshot) => (
                        <CarList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            $isDraggingOver={snapshot.isDraggingOver}
                        >
                            {this.props.cars.length > 0
                                ? this.props.cars.map((car, index) => (
                                    <Car
                                        key={car.id}
                                        car={car}
                                        index={index}
                                        racks={this.props.racks}
                                        showCarDetails={false}
                                        disableDragDrop={this.props.disableDragDrop ?? false}
                                        isOnEngine={this.props.track.isEngine}
                                    />
                                ))
                                : <Empty>Empty {this.props.track.isEngine ? 'Engine' : 'Track'}</Empty>}
                            {provided.placeholder}
                        </CarList>
                    )}
                </Droppable>
            </Container >
        );
    }
}
