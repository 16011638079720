import Api from "./Api";

export default async function fetchFileContents(readLocation, filePath, outFileName, msalInstance) {

  let res = null;
  await Api.GET(readLocation,
    {
      filename: filePath,
      outfilename: outFileName,
    },
    data => {
      res = data;
    },
    err => {
      console.log('error', err);
      alert('error! ' + err);
    },
    msalInstance
  );
  return res;
}
