import React from 'react';
import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react'
import App from './main';


function Auth(props) {
  const isAuthenticated = useIsAuthenticated();

  return (
    isAuthenticated
      ?
      <App />
      :
      <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
        <App />
      </MsalAuthenticationTemplate>
  );
};

export default Auth;