import { Group, Stack, Text, Container } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import FileLoaderWrapper from './fileloader-wrapper';

// const Container = styled.div`
//   border: 1px solid #D4D4D4;
//   height: 100%;
//   padding: 8px;
//   margin: 8px 8px 16px 8px;
// `;

const FailedRun = styled.div`
  font-weight: bold;
  color: red;
`;


export default class NotificationArea extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      disabledTracksMsg: '<None>',
      disabledBaysMsg: '<None>',
    }
  }
  componentDidMount() {
    this.refreshMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.simResults !== this.props.simResults) {
      this.refreshMessages();
    }
  }

  refreshMessages = () => {
    let disabledTracksMsg = '<None>';
    if (this.props.simResults?.disabledTracks) {
      disabledTracksMsg = this.props.simResults?.disabledTracks?.join(', ') ?? '<None>';
    }

    let disabledBaysMsg = '<None>';
    const keys = Object.keys(this.props.simResults?.disabledBays ?? {});
    if (keys?.length > 0) {
      disabledBaysMsg = this.props.simResults?.disabledBays[keys[0]].join(', ') ?? '<None>';
    }
    const newState = {
      ...this.state,
      disabledTracksMsg: disabledTracksMsg,
      disabledBaysMsg: disabledBaysMsg,
    };
    this.setState(newState);
  }

  render() {
    return (
      <Container bd='1px solid #D4D4D4' h='100%' fluid={true} >
        <Group>
          <Stack gap={6} mt={24} justify='flex-start'>
            <Text size='md'>Current Simulation Results</Text>
            <Text size='xs'>Source Yard: {this.props.simResults?.source_yard ?? ''}</Text>
            <Text size='xs'>Target Yard: {this.props.simResults?.target_yard ?? ''}</Text>
            <Text size='xs'>Closed Tracks: {this.state?.disabledTracksMsg ?? ''}</Text>
            <Text size='xs'>Closed Bays: {this.state?.disabledBaysMsg ?? ''}</Text>
            <Text size='xs'>Requested: {this.props.requestedTime}</Text>
          </Stack>
          {typeof (this.props.simResults?.simulation_results) === 'string'
            ? <FailedRun>{this.props.simResults.simulation_results}</FailedRun>
            : null}

          <FileLoaderWrapper
            prompt='Get Latest Results'
            fileLocation={process.env.REACT_APP_SIM_RESULTS_SERVICE}
            fileFolder={process.env.REACT_APP_SIM_RESULTS_FILE_FOLDER}
            fileName={process.env.REACT_APP_SIM_RESULTS_FILE_NAME}
            currentFile={this.props.simResults}
            lastModified={this.props.lastModified}
            onChange={this.props.retrieveLatestSimResults}
            isWaiting={this.props.isSimulatorRunning}
            resultStatus={this.props.simulatorResultStatus}
            ETA={this.props.simulatorResultsETA}
          />
        </Group>
      </Container>
    );
  }
}