export function convertFromSim(filedata) {
  //the file is in the following format:

  //{
  //........
  // "18": {
  //     "track_number": "Track 5",
  //     "track_detail": "",
  //     "location": 2,
  //     "car_type": "UTLX",
  //     "car_sr_number": 684302,
  //     "contents": "DIESEL MGO",
  //     "L\/E": "E",
  //     "Disposition": "10\/11;1-4",
  //     "loaded": "0",
  //     "empty": "1",
  //     "due_for_qual": "0",
  //     "dispostion_detail": "CN",
  //     "track_0": [["10","1,2,3,4"],["11","1,2,3,4"]],
  //     "track_1": ["'10'"],
  //     "slot_1": [" '1,2,3,4'"],
  //     "track_2": ["'11'"],
  //     "slot_2": [" '1,2,3,4'"]
  // },
  //........
  //}

  // const alteredFiledata = filedata.toString().replace("\"L\/E\":", "\"LE:\"");

  // the file starts with object "0" and continues through the number of total objects
  const carArr = [];
  for (let [key, value] of Object.entries(filedata)) {
    if (value.car_type !== "") {
      carArr.push(getCarData(key, value));
    }
  }
  const allCars = addMyObjs({}, carArr);
  const tracks = getTrackCars(filedata);

  return [allCars, tracks];
}

//convert array to an object of objects
function addMyObjs(objInit, arrWithObjs) {
  let map = new Map();
  for (let [key, value] of Object.entries(objInit)) {
    map.set(key, value);
  }
  arrWithObjs.forEach((item) => {
    for (let [key, value] of Object.entries(item)) {
      map.set(key, value);
    }
  });
  return Object.fromEntries(map);
}

///'CBTX 717275': { id: 'CBTX 717275', contents: 'ELBOW HFO HFOTI', le: 'mt', disposition: 'bunker', location: '1', isSelected: false, },
function getCarData(key, value) {
  let car = {};
  let keyid = value.car_type + " " + value.car_sr_number.toString();
  // const id = keyid;
  if (keyid === "OUT 0") {
    keyid += ` ${key}`;
  }

  //   if (keyid === "ICRX 211368") {
  //     console.log("value", value);
  //   }

  car[keyid] = {
    id: keyid,
    contents: value.contents,
    le: value.LE === "L" ? "L" : value.LE === "PL" ? "pL" : "E",
    disposition_detail: value.disposition_detail,
    disposition: value.Disposition,
    destination: value.destination, // cn/cp/spur/[empty]
    location: value.location,
    isSelected: false,
    isSpotted: value.LE === "S" ? true : false,
    isAddingToEngine: false,
    isRemovingFromEngine: false,
    due_for_qual: value.due_for_qual,
    loaded: value.loaded,
    empty: value.empty,
    track_0: value.track_0,
    track_1: value.track_1,
    slot_1: value.slot_1,
    track_2: value.track_2,
    slot_2: value.slot_2,
    watch: value.watch_flag,
  };
  return car;
}

function getTrackCars(filedata) {
  let tracks = [];

  for (let [key, value] of Object.entries(filedata)) {
    if (value.track_number !== "") {
      // let fullTrackName = value.track_number + (value.track_detail === "" ? "" : " " + value.track_detail);
      let fullTrackName = value.track_number;

      let currCarName = value.car_type + " " + value.car_sr_number.toString();
      if (currCarName === "OUT 0") {
        //this is an OUT OF SERVICE spot, not a car
        currCarName += ` ${key}`;
        // console.log('currCarName', currCarName);
      }

      //Track 11 Stub is a special case
      if (fullTrackName.includes("11") && value.track_detail === "Stub") {
        fullTrackName += " Stub";
      }
      let existingTrack = tracks.find((x) => x.trackName === fullTrackName);

      if (existingTrack) {
        // existingTrack.carIds.push(currCarName);
        if (currCarName !== " 0") {
          // existingTrack.carIds.push(currCarName);
          //it appears that the higher number slots are near the terminus...insert them instead of adding to the end
          existingTrack.carIds.unshift(currCarName);
        }
      } else {
        let currTrack = {
          trackName: fullTrackName,
          carIds: currCarName === " 0" ? [] : [currCarName],
        };
        tracks.push(currTrack);
      }
    }
  }
  // console.log(tracks);
  return tracks;
}
