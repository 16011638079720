import React, { useEffect, useState } from "react";
import { Button, FileButton } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import styled from "styled-components";
import fetchFileMetadata from "./file-info-fetcher";
import Api from "./Api";
import { useMsal } from "@azure/msal-react";
import fetchFileContents from "./file-contents-fetcher";
const { date2str } = require("./date-format");

const Container = styled.div`
  display: inline-block;
  margin: 0 8px 0 8px;
  padding: 4px;
`;

const LastUpdatedInfo = styled.div`
  font-size: 10px;
`;

// export default class Upload35YardFile extends React.Component {
function Upload35YardFile(props) {
  const { instance } = useMsal();
  const [waitingForParsedFile, setWaitingForParsedFile] = useState(false);
  const [parseCompleteETA, setParseCompleteETA] = useState(
    Number(process.env.REACT_APP_YARD_FILE_LOAD_ETA_SECONDS)
  );
  const maxWaitSeconds = Number(
    process.env.REACT_APP_YARD_FILE_LOAD_MAX_WAIT_SECONDS
  );
  const resultsFileName =
    process.env.REACT_APP_LATEST_YARD35_FILE_FOLDER +
    process.env.REACT_APP_LATEST_YARD35_FILE_NAME;
  const fileParseComplete = props.fileParsingComplete;

  useEffect(() => {
    const retrieveLatestFile = async () => {
      try {
        const fileResponse = await fetchFileMetadata(resultsFileName, instance);

        const fileLoc =
          process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_LATEST_YARD35_SERVICE;
        const fileData = await fetchFileContents(
          fileLoc,
          resultsFileName,
          process.env.REACT_APP_LATEST_YARD35_FILE_NAME,
          instance
        );

        const foundDate = fileResponse
          ? new Date(Date.parse(fileResponse.lastModified))
          : null;
        const dateString = foundDate
          ? date2str(foundDate, "yyyy-MM-dd hh:mm:ss")
          : "";

        const fileInfo = {
          contents: fileData,
          lastUpdate: dateString ?? "",
        };
        fileParseComplete(fileInfo);
      } catch (err) {
        //there may be no file, ignore for now
        console.log("err getting initial 35 yard file:", err);
      }
    };

    retrieveLatestFile();
  }, [instance, fileParseComplete, resultsFileName]);

  const handleFileUpload = async (fileInfo) => {
    if (!fileInfo) {
      alert("Please select a file to upload");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", fileInfo);
      let result = null;

      // const result = await axios.post(process.env.REACT_APP_SERVER_URL + '/upload35YardFile', formData, config);
      await Api.POST(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_UPLOAD_YARD35_SERVICE,
        formData,
        true, //is max length
        true, //use form data
        (res) => {
          result = res.result;
        },
        (err) => {
          console.log("error uploading 35 yard file", err);
          alert("failed to upload 35 yard file", err);
        },
        instance
      );

      if (!result) {
        return;
      }

      // console.log('result', result);
      // props.yardFileLastModifiedDate(result.lastModified);

      props.yardFileUploaded(result.lastModified);

      let lastDate = result.lastModified;
      let fileResponse = null;
      setWaitingForParsedFile(true);

      const startTime = new Date();

      while (lastDate <= result.lastModified) {
        //check to see if we exceeded the maximum wait time
        const runningWaitTime = Math.round((new Date() - startTime) / 1000);
        // setElapsedSeconds(runningWaitTime);
        setParseCompleteETA(
          Math.max(
            0,
            Number(process.env.REACT_APP_YARD_FILE_LOAD_ETA_SECONDS) -
              runningWaitTime
          )
        );
        setWaitingForParsedFile(true);

        if (runningWaitTime > maxWaitSeconds) {
          setWaitingForParsedFile(false);
          throw new Error(
            "The file was not loading in the maximum wait time.  If this persists, please contact support"
          );
        }

        // fileResponse = await fetchFileMetadata(this.state.resultsFileName);
        fileResponse = await fetchFileMetadata(resultsFileName, instance);
        lastDate = fileResponse.lastModified;

        // console.log('lastDate', lastDate, 'result.lastModified', result.lastModified);

        await sleep(5000);
      }
      setWaitingForParsedFile(false);

      if (fileResponse) {
        const fileLoc =
          process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_LATEST_YARD35_SERVICE;
        const fileData = await fetchFileContents(
          fileLoc,
          resultsFileName,
          process.env.REACT_APP_LATEST_YARD35_FILE_NAME,
          instance
        );

        const foundDate = fileResponse
          ? new Date(Date.parse(fileResponse.lastModified))
          : null;
        const dateString = foundDate
          ? date2str(foundDate, "yyyy-MM-dd hh:mm:ss")
          : "";

        const fileInfo = {
          contents: fileData,
          lastUpdate: dateString ?? "",
        };
        // this.props.fileParsingComplete(fileInfo);
        props.fileParsingComplete(fileInfo);
      }
    } catch (error) {
      console.log("Error uploading file", error);
      alert("Error uploading file: " + error);
    } finally {
      setWaitingForParsedFile(false);
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setInterval(resolve, ms));
  };

  return (
    <Container>
      <FileButton
        onChange={handleFileUpload}
        accept=".xlsx"
        size="xs"
        variant="filled"
      >
        {(props) => (
          <Button
            {...props}
            leftSection={<IconUpload size={14} />}
            loading={waitingForParsedFile}
          >
            Upload Yard 35 File
          </Button>
        )}
      </FileButton>
      <LastUpdatedInfo>
        Last Updated: {props.yardFileLastModifiedDate}
      </LastUpdatedInfo>
      <div>
        {waitingForParsedFile === true ? (
          <div>ETA: {parseCompleteETA} seconds</div>
        ) : null}
      </div>
    </Container>
  );
}

export default Upload35YardFile;
