import React from 'react';
import { Button, Container, Loader, Stack, Text } from '@mantine/core';
import { IconArrowRight, IconCheck } from '@tabler/icons-react';
import fetchFileMetadata from './file-info-fetcher';
import fetchFileContents from './file-contents-fetcher';
const { date2str } = require('./date-format');


export default class FileLoader extends React.Component {


    componentDidMount() {
        //option to automatically run on render
        if (this.props.autoClick === true) {
            this.retrieveLatestFile();
        }
    };

    retrieveLatestFile = async () => {
        try {
            //get all files and find the one we want
            const readLoc = process.env.REACT_APP_SERVER_URL + this.props.fileLocation;
            const filePath = this.props.fileFolder + this.props.fileName;

            const fileData = await fetchFileContents(readLoc, filePath, this.props.fileName, this.props.msalInstance);

            //now get the last modified file date
            const fileResponse = await fetchFileMetadata(filePath, this.props.msalInstance);

            const foundDate = fileResponse ? new Date(Date.parse(fileResponse.lastModified)) : null;
            const dateString = foundDate ? date2str(foundDate, 'yyyy-MM-dd hh:mm:ss') : '';

            const fileInfo = {
                contents: fileData,
                lastUpdate: dateString ?? '',
            }
            this.props.onChange(fileInfo);
        } catch (error) {
            alert('Error fetching files', error);
        }
    };


    render() {
        return (
            <Container display='flex' mih={130} miw={120} mt={24} ml={16} p={0} radius={4} onClick={this.retrieveLatestFile}>
                {/* <Button h='100%' w={100} size='xs' onClick={this.retrieveLatestFile}>{this.props.prompt}</Button>&nbsp; */}
                {this.props.isWaiting
                    ?
                    <Stack align='center' bg='#ECF5FF'>
                        <Text>{this.props.resultStatus}</Text>
                        <Loader size='sm'></Loader>
                        <Text size='xs'>Estimated Time Remaining</Text>
                        <Text size='sm'>{this.props.ETA} seconds</Text>
                    </Stack>
                    :
                    <Stack align='center' bg='#2469BD' justify='center' gap={10} c='white' w={120}>
                        <Text>{this.props.resultStatus}</Text>
                        <IconCheck />
                        <Button
                            variant='transparent'
                            c='white'
                            size='xs'
                            rightSection={<IconArrowRight w={6} h={6} />}>
                            View
                        </Button>
                    </Stack>
                }

            </Container>
        );
    };
}