import React from "react";
import styled from 'styled-components';
// import SimTrack from "./sim-track";
import Track from './track';
import { Grid, Stepper, Text } from "@mantine/core";

const Container = styled.div`
  width: 100%;
`;

const YardWrapper = styled.div`
padding: 8px;
background-color: white;
display: ${props => props.$isVisible ? 'block' : 'none'};
`;

const YardConfigurationWrapper = styled.div`
background-color: #D4D4D4;
padding: 8px;
margin: 8px;
zoom: 50%;
`;

const YardTitle = styled.h3`
`;

export class SimStepsListDisplay extends React.PureComponent {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showStepsInitialFinalConfig !== this.props.showStepsInitialFinalConfig) {
      //re-render
    }
  }

  render() {
    return (
      <Container>
        <Grid m={8}>
          <Grid.Col span={this.props.showStepsInitialFinalConfig ? 5 : 12} title={this.props.showStepsInitialFinalConfig ? 5 : 12}>
            <Stepper orientation="vertical">
              {
                // don't show the "Initial Configuration" or "Final Configuration" steps in the list
                this.props.moveSequence?.filter(x => x.id > 0 && x.id < this.props.moveSequence?.length - 1).map((move) => {
                  return <Stepper.Step
                    key={move.id}
                    description={move.stepText}
                  >
                  </Stepper.Step>
                })
              }
            </Stepper>
          </Grid.Col>

          {this.props.showStepsInitialFinalConfig
            ? <Grid.Col span={7}>
              <YardConfigurationWrapper>
                <Text fw={"bold"}>Initial Configuration:</Text>
                {this.props.moveSequence ? Object.values(this.props.allYards).map((yard) => {
                  return <YardWrapper
                    key={yard.id}
                    $isVisible={yard.visible}>
                    <YardTitle>{yard.id}</YardTitle>
                    {yard.trackIds.map((trackId) => {
                      const initialConfiguration = this.props.moveSequence[0];
                      if (initialConfiguration) {
                        const track = initialConfiguration.tracks[trackId];
                        if (track) {
                          if (!this.props.onlyDisplayAffectedTracks || this.props.affectedTracks.includes(trackId)) {
                            const cars = track.carIds.map(carId => initialConfiguration.cars[carId]);
                            return <Track
                              key={track.id}
                              track={track}
                              cars={cars}
                              showCarDetails={false}
                              racks={this.props.racks}
                            />
                          }
                        }
                      }
                      return null;
                    }
                    )}
                  </YardWrapper>
                })
                  : null}
              </YardConfigurationWrapper>

              <YardConfigurationWrapper>
                <Text fw={"bold"}>Final Configuration:</Text>
                {this.props.moveSequence ? Object.values(this.props.allYards).map((yard) => {
                  return <YardWrapper
                    key={yard.id}
                    $isVisible={yard.visible}>
                    <YardTitle>{yard.id}</YardTitle>
                    {yard.trackIds.map((trackId) => {
                      const finalConfiguration = this.props.moveSequence[Object.keys(this.props.moveSequence).pop()];
                      if (finalConfiguration) {
                        const track = finalConfiguration.tracks[trackId];
                        if (track) {
                          if (!this.props.onlyDisplayAffectedTracks || this.props.affectedTracks.includes(trackId)) {
                            const cars = track.carIds.map(carId => finalConfiguration.cars[carId]);
                            return <Track
                              key={track.id}
                              track={track}
                              cars={cars}
                              showCarDetails={false}
                              racks={this.props.racks}
                            />
                          }
                        }
                      }
                      return null;
                    }
                    )}
                  </YardWrapper>
                })
                  : null}
              </YardConfigurationWrapper>
            </Grid.Col>
            : <Grid.Col span={0}></Grid.Col>}

        </Grid>
      </Container>
    )
  }
}