import React from 'react';
import '@mantine/core/styles.css';
import '@atlaskit/css-reset';
import styled from 'styled-components';
import { DragDropContext } from '@hello-pangea/dnd';
import { convertFromSim } from './convert-sim-file';
import yardLayout from './yard-layout'
import Track from './track';
import ResultSelector from './resultselector';
import { Checkbox, Divider, Grid, Group, MantineProvider, Paper, Stack, Tabs, Text, Image } from '@mantine/core';
import Upload35YardFile from './yard35-uploader';
import FlaggedCars from './flagged-cars';
import NotificationArea from './notification-area';
import ZoomControl from './zoom-control';
import SimRequestWrapper from './sim-request-wrapper';



const BodyContainer = styled.div`
  margin: 16px 16px 0 16px;
`;


const LocationTitle = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const HeaderSeparator = styled.div`
  margin-top: 8px;
`;


const ResultsOutOfDateInfo = styled.div`
  display: inline;
  color: red;
  font-weight: bold;
`;

const YardWrapper = styled.div`
  // margin: 8px;
  // padding: 8px;
  // border: 1px solid black;
  background-color: white;
  display: ${props => (props.$isVisible ? 'block' : 'none')};
`;

const YardTitle = styled.h3`
`;

const ResultsViewer = styled.div`
  display: ${props => (props.$isVisible ? 'block' : 'none')};
  background-color: white;
`;

const YardZoomWrapper = styled.div`
  zoom: ${props => (props.$zoomPercent)}%;
`;

const YardEditor = styled.div`
  display: ${props => (props.$isVisible ? 'block' : 'none')};
  background-color: white;
  margin-left: 8px;
`;



export default class App extends React.Component {
  state = yardLayout;


  componentDidMount() {
    const newState = {
      ...this.state,
      selectedTab: 'YardEditor',
      disabledTracks: [],
      disabledBays: {},
      waitingForSimulatorResults: false,
      simulatorResultStatus: 'Ready',
      simulatorResultsETA: 0,
    };
    this.setState(newState);
  };


  onDragEndMultiSelect = result => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    //if we drag and drop to the same index, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index &&
      destination.trackId === source.trackId
    ) {
      return;
    }

    //first, check if any of the cars in the source track were selected
    //  - if there were selected cars, check if the draggableId value matches the IDs of any of the selected cars
    //    - if the draggableId is in the list of selected IDs, identify them all and move them _in order_
    //    - else: move only the dragged item and deselect all cars
    //  - else: move only the dragged item and deselect all cars

    const selectedIds = this.getSelectedCarList();
    if (selectedIds.length === 0) {
      this.onDragEndSingle(result);
      return;
    }
    else if (selectedIds.length === 1 && draggableId === selectedIds[0]) {
      //we are dragging the only selected item
      this.onDragEndSingle(result);
      const draggedCar = Object.values(this.state.cars).find(value => value.id === draggableId);
      draggedCar.isSelected = false;
      return;
    }
    else if (selectedIds.length === 1 && selectedIds.includes(draggableId) === false) {
      //not dragging the selected item(s)
      this.onDragEndSingle(result);
      this.deselectAllCars();
      return;
    }


    const start = this.state.tracks[source.droppableId];
    const finish = this.state.tracks[destination.droppableId];

    if (start === finish) {
      //dragging within the same track -- special issues
      const trackCarIds = this.state.tracks[destination.droppableId].carIds;
      let dropIndex = destination.index;

      const orderedSelectedIds = this.getSelectedCarIdsFromTrackInOrder(selectedIds, trackCarIds);

      //find the dropIndex after the selectedIds are removed
      for (let index = 0; index < trackCarIds.length; index++) {
        if (index === dropIndex) {
          break;
        }
        let currCarId = trackCarIds[index];
        //count how many of the selectedIds are _before_ the dropIndex, and decrement the dropIndex accordingly
        if (selectedIds.includes(currCarId)) {
          dropIndex--;
        }
      }
      if (source.index < destination.index) {
        dropIndex++;  //when we begin a drag, the dragged item is removed, and everything shifts left, throwing off the index
      }


      const newCarIds = trackCarIds.filter((el) => !selectedIds.includes(el));
      for (let i = 0; i < orderedSelectedIds.length; i++) {
        newCarIds.splice(dropIndex + i, 0, orderedSelectedIds[i]);
      }

      const newTrack = {
        ...start,
        carIds: newCarIds,
      };

      const newState = {
        ...this.state,
        tracks: {
          ...this.state.tracks,
          [newTrack.id]: newTrack,
        },
      };
      this.setState(newState);
      this.deselectAllCars();
      // }
      return;
    }


  };

  getSelectedCarList = () => {
    const selectedIds = [];
    for (const [, value] of Object.entries(this.state.cars)) {
      if (value.isSelected) {
        selectedIds.push(value.id);
      }
    };
    return selectedIds;
  };

  getSelectedCarIdsFromTrackInOrder = (selectedIds, trackCarIds) => {
    const orderedSelectedIds = [];
    for (let i = 0; i < trackCarIds.length; i++) {
      if (selectedIds.includes(trackCarIds[i])) {
        orderedSelectedIds.push(trackCarIds[i]);
      }
    }
    return orderedSelectedIds;
  };

  deselectAllCars = () => {
    //const draggedCar = Object.values(this.state.cars).find(value => value.id === draggableId);
    for (const [, value] of Object.entries(this.state.cars)) {
      if (value.isSelected) {
        value.isSelected = false;
      }
    };
  };

  onDragEndSingle = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index &&
      destination.trackId === source.trackId
    ) {
      return;
    }

    const start = this.state.tracks[source.droppableId];
    const finish = this.state.tracks[destination.droppableId];

    if (start === finish) {

      const newCarIds = Array.from(start.carIds);
      newCarIds.splice(source.index, 1);
      newCarIds.splice(destination.index, 0, draggableId);

      const newTrack = {
        ...start,
        carIds: newCarIds,
      };

      const newState = {
        ...this.state,
        tracks: {
          ...this.state.tracks,
          [newTrack.id]: newTrack,
        },
      };
      this.setState(newState);

      return;
    }

    //Moving from one list to another

    //If none of the cars are selected, it's a normal move
    // console.log(draggableId);

    //first, check if any of the cars in the source track were selected
    //  - if there were selected cars, check if the draggableId value matches the IDs of any of the selected cars
    //    - if the draggableId is in the list of selected IDs, identify them all and move them _in order_
    //    - else: move only the dragged item and deselect all cars
    //  - else: move only the dragged item and deselect all cars

    const startCarIds = Array.from(start.carIds);
    startCarIds.splice(source.index, 1);
    const newStart = {
      ...start,
      carIds: startCarIds,
    };

    const finishCarIds = Array.from(finish.carIds);
    finishCarIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      carIds: finishCarIds,
    };

    const newState = {
      ...this.state,
      tracks: {
        ...this.state.tracks,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      }
    }
    this.setState(newState);
  };

  yardCheckChanged = (e) => {
    const yardUpdate = this.state.yards[e.target.name];
    yardUpdate.visible = !yardUpdate.visible;

    const newState = {
      ...this.state,
      yards: {
        ...this.state.yards,
        [yardUpdate.id]: yardUpdate,
      }
    };

    this.setState(newState);
  };

  new35FileUpload = (lastModifiedDate) => {
    // console.log('new35FileUpload', lastModifiedDate);
    // this.state.yardFileLastModifiedDate
    const newState = {
      ...this.state,
      yardFileLastModifiedDate: lastModifiedDate,
    };
    this.setState(newState);
  };

  initialYardFileSelected = (fileInfo) => {
    const simdata = fileInfo.contents;
    const lastModified = fileInfo.lastUpdate;

    const [allCars, tracks] = convertFromSim(simdata);

    //replace all current cars with cars from the 35 Yard file
    let newState = {
      ...this.state,
      initialYardFile: '',
      yardFileLastModifiedDate: lastModified,
      cars: allCars,
      selectedTab: 'YardEditor',
    }

    for (let [key,] of Object.entries(this.state.tracks)) {
      let currTrack = this.state.tracks[key];

      let newTrackInfo = tracks.find(x => x.trackName === key);
      if (newTrackInfo) {

        let newTrack = {
          ...currTrack,
          carIds: newTrackInfo.carIds,
        }

        newState = {
          ...newState,
          tracks: {
            ...newState.tracks,
            [newTrack.id]: newTrack,
          }
        }
      }
      else {
        let newTrack = {
          ...currTrack,
          carIds: [],
        }
        //still need to remove cars
        newState = {
          ...newState,
          tracks: {
            ...newState.tracks,
            [newTrack.id]: newTrack,
          }
        }
      }
    }

    this.setState(newState);
  };



  resultsFileSelected = (fileInfo) => {

    const resultsData = fileInfo.contents;
    // console.log('fileInfo', fileInfo);
    const lastModified = fileInfo.lastUpdate;

    // console.log('resultsFileSelected', resultsData);
    const resultIds = [];
    for (const key of Object.keys(resultsData.simulation_results)) {
      resultIds.push(key);
    }

    //only select the working yard checkboxes when loading
    //only select the working_yard and target_yard checkboxes (could be the same) when loading
    const visibleYards = [resultsData.source_yard, resultsData.target_yard];
    let newYards = {
      ...this.state.yards,
    };

    const yardNames = Object.keys(this.state.yards);
    yardNames.forEach(name => {
      let currYard = this.state.yards[name];
      currYard.visible = visibleYards.includes(currYard.id);
      newYards = {
        ...newYards,
        [currYard.id]: currYard,
      };
    });

    const newState = {
      ...this.state,
      yards: newYards,
      simResults: resultsData,
      simResultsLastModifiedDate: lastModified,
      // resultsFile: selectedFile,
      resultsFileLoaded: true,
      selectedTab: 'ResultsViewer',
      simResultIds: resultIds,
      selectedSimResult: -1,
    };
    this.setState(newState);
    // console.log('newState', newState);
  };

  changeViewTab = (sel) => {
    const newState = {
      ...this.state,
      // viewResults: sel === 'ResultsViewer',
      selectedTab: sel,
    };
    this.setState(newState);
  };


  simRequestFound = (simRequestDate) => {
    // console.log('simRequestFound', simRequestDate);
    const newState = {
      ...this.state,
      simRequestLastModifiedDate: simRequestDate,
    }
    this.setState(newState);
  }

  simulatorRunning = (isRunning, status, ETA = 0) => {
    const newState = {
      ...this.state,
      isSimulatorRunning: isRunning,
      simulatorResultStatus: status,
      simulatorResultsETA: ETA,
    }
    this.setState(newState);
  }

  disabledBaysChanged = (bays) => {
    const newState = {
      ...this.state,
      disabledBays: bays,
    };
    this.setState(newState);
  }

  disabledTracksChanged = (trackIds) => {
    const newState = {
      ...this.state,
      disabledTracks: trackIds,
    };
    this.setState(newState);
  }

  yardEditorZoomUpdated = (zoom) => {
    const newState = {
      ...this.state,
      yardEditorZoom: zoom,
    };
    this.setState(newState);
  }


  render() {
    return (

      <MantineProvider
        theme={{
          primaryColor: 'main-blue',
          primaryShade: 1,
          colors: {
            'main-blue': ['#8FB4E2', '#2469BD', '#1F497E', '#00000026', '#00000033', '#000000E6', '#00000080', '#0000004D', '#0000001A', '#FFFFFF'],
          },
          autoContrast: true,
          defaultRadius: 2,
        }}
      >
        <Stack align='stretch' justify='flex-start' gap='sm' bg='#D6E9FF'>
          <Paper shadow='sm' radius={0}>
            <Group>
              <Image src='/Tool Icon.png' pl={16}></Image>
              <Text size='xl' bg='white' fw={500}>Rail Yard Switching Simulator</Text>
            </Group>
          </Paper>

          <Grid justify='space-between' align='stretch' m='0 32px 0 32px'>
            <Grid.Col span='content' bg='white'>
              <Group>
                <Stack>
                  <LocationTitle>
                    <Text size='lg'>Edmonton Rail Yard</Text>
                  </LocationTitle>
                  <Upload35YardFile
                    yardFileLastModifiedDate={this.state.yardFileLastModifiedDate}
                    yardFileUploaded={this.new35FileUpload}
                    fileParsingComplete={this.initialYardFileSelected}
                  />
                  <Divider m='0 8 0 8' />

                  <FlaggedCars
                    cars={this.state.cars}
                    tracks={this.state.tracks}
                  />

                </Stack>
              </Group>
            </Grid.Col>
            <Grid.Col span='content' w={32}>

            </Grid.Col>
            <Grid.Col span={6} align='flex-start' bg='white'>
              <Group>
                <SimRequestWrapper
                  yards={this.state.yards}
                  trackOrder={[].concat(this.state.yardANorthTrackOrder, this.state.yardASouthTrackOrder, this.state.yardBTrackOrder, this.state.yardCTrackOrder)}
                  cars={this.state.cars}
                  tracks={this.state.tracks}
                  racks={this.state.racks}
                  disabledTracks={this.state.disabledTracks}
                  disabledBays={this.state.disabledBays}
                  onSimRequestFound={this.simRequestFound}
                  latestSimRequestDate={this.state.simRequestLastModifiedDate}
                  onSimRequestComplete={this.resultsFileSelected}
                  disabledTracksChanged={this.disabledTracksChanged}
                  disabledBaysChanged={this.disabledBaysChanged}
                  simulatorRunning={this.simulatorRunning}
                  isSimulatorRunning={this.state.isSimulatorRunning}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span='auto' align='flex' bg='white'>
              <NotificationArea
                workingYard={this.state.workingYard}
                simResults={this.state.simResults}
                targetTrack={this.state.resultsData}
                disabledTracks={this.state.disabledTracks}
                disabledBays={this.state.disabledBays}
                requestedTime={this.state.simRequestLastModifiedDate}
                lastModified={this.state.simResultsLastModifiedDate}
                retrieveLatestSimResults={this.resultsFileSelected}
                isSimulatorRunning={this.state.isSimulatorRunning}
                simulatorResultStatus={this.state.simulatorResultStatus}
                simulatorResultsETA={this.state.simulatorResultsETA}
              />
            </Grid.Col>
          </Grid>


          <BodyContainer>
            <ResultsOutOfDateInfo>
              {this.state.simResultsLastModifiedDate < this.state.simRequestLastModifiedDate ||
                this.state.simResultsLastModifiedDate < this.state.yardFileLastModifiedDate ? 'Results out of date' : ''}
            </ResultsOutOfDateInfo>
            <Tabs
              value={this.state?.selectedTab}
              variant='pills'
              onChange={this.changeViewTab}
              p={8}
              radius={0}
            >
              <Tabs.List fw={'bold'}>
                <Tabs.Tab
                  value='YardEditor'
                  bg={this.state?.selectedTab === 'YardEditor' ? 'main-blue.9' : 'main-blue.0'}
                  c={this.state?.selectedTab === 'YardEditor' ? 'main-blue.1' : '#F1F2F2'}
                >
                  Current Yard Configuration</Tabs.Tab>
                <Tabs.Tab
                  value='ResultsViewer'
                  bg={this.state?.selectedTab === 'ResultsViewer' ? 'main-blue.9' : 'main-blue.0'}
                  c={this.state?.selectedTab === 'ResultsViewer' ? 'main-blue.1' : '#F1F2F2'}
                >
                  Simulation Results</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='YardEditor' bg='white'>
                <YardEditor
                  $isVisible={this.state.selectedTab === 'YardEditor'}
                >
                  <Grid grow={true} align='center' styles={{ inner: { margin: '0' } }}>
                    <Grid.Col span={10} m={0}>
                      <Group p={12} gap={32}>
                        <Checkbox label='Yard A North' name='Yard A North' checked={this.state.yards['Yard A North'].visible} onChange={this.yardCheckChanged} size='xs' />
                        <Checkbox label='Yard A South' name='Yard A South' checked={this.state.yards['Yard A South'].visible} onChange={this.yardCheckChanged} size='xs' />
                        <Checkbox label='Yard B' name='Yard B' checked={this.state.yards['Yard B'].visible} onChange={this.yardCheckChanged} size='xs' />
                        <Checkbox label='Yard C' name='Yard C' checked={this.state.yards['Yard C'].visible} onChange={this.yardCheckChanged} size='xs' />

                      </Group>

                    </Grid.Col>
                    <Grid.Col span='content'>
                      <ZoomControl zoomUpdated={this.yardEditorZoomUpdated}></ZoomControl>
                    </Grid.Col>
                  </Grid>
                  <Divider />
                  <HeaderSeparator />
                  <DragDropContext
                    onDragEnd={this.onDragEndMultiSelect}
                  >
                    <YardZoomWrapper $zoomPercent={this.state?.yardEditorZoom}>
                      {Object.values(this.state?.yards).filter(x => x.visible).map((yard) => {
                        return <YardWrapper
                          key={yard.id}
                          $isVisible={yard.visible}>
                          <YardTitle>{yard.id}</YardTitle>
                          {yard.trackIds.map((trackId) => {
                            const track = this.state?.tracks[trackId];
                            const cars = track?.carIds?.map(carId => this.state?.cars[carId]);

                            return <Track key={track.id} track={track} cars={cars} racks={this.state.racks} disabledTracks={this.state.disabledTracks} />
                          })}
                        </YardWrapper>
                      })}
                    </YardZoomWrapper>
                  </DragDropContext>
                </YardEditor>
              </Tabs.Panel>

              <Tabs.Panel value='ResultsViewer' bg={'white'} bd={'1px solid white'} p={8}>
                <ResultsViewer
                  $isVisible={this.state.selectedTab === 'ResultsViewer'}
                >
                  <ResultSelector
                    simResults={this.state.simResults}
                    simResultIds={this.state.simResultIds}
                    allCars={this.state.cars}
                    initialTrackLayout={this.state.tracks}
                    allYards={this.state.yards}
                    racks={this.state.racks}
                    yardEngineIds={this.state.yardEngineIds}
                  />
                </ResultsViewer>
              </Tabs.Panel>
            </Tabs>
          </BodyContainer >
        </Stack >

      </MantineProvider>
    );

  }
}
