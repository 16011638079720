
export function getMoveSequence(simresults, selectedSimResult, cars, initialTrackLayout, yardEngineIds) {

  const stepsListWrapper = {
    affectedTracks: [],
    calculatedSteps: null,
  };

  if (!simresults?.simulation_results)
    return stepsListWrapper;
  if (selectedSimResult < 0)
    return stepsListWrapper;
  if (!initialTrackLayout || !cars)
    return stepsListWrapper;

  //set up the initial track layout
  const calculatedSteps = [{
    id: 0,
    stepText: 'Initial Configuration',
    cars: cars,
    tracks: initialTrackLayout
  }];

  stepsListWrapper.calculatedSteps = calculatedSteps;

  const currSimResultMoves = simresults.simulation_results[selectedSimResult].move_sequence;

  const keys = Object.keys(currSimResultMoves);

  let prevRemovalTrackOrEngine = null;
  let foundTrack = null;
  let foundEngine = null;

  for (let i = 0; i < keys.length; i++) {
    //there is no currSimResultsMoves[0] -- they start at step 1
    const currSimResultStep = currSimResultMoves[i + 1];
    if (!currSimResultStep) {
      continue;
    }

    let prevStep = calculatedSteps[i];
    let newStep = {
      ...prevStep,
      id: i + 1,
    };

    let currStepCars = {
      ...prevStep.cars,
    };


    //if a car was highlighted for removal from an engine or track in the previous step, we need to actually remove it here
    currStepCars = clearPreviousStepsCarHighlighting(prevStep.cars, currStepCars);


    newStep = {
      ...newStep,
      cars: currStepCars,
    };


    if (prevRemovalTrackOrEngine) {
      const newTracks = {
        ...newStep.tracks,
        [prevRemovalTrackOrEngine.id]: prevRemovalTrackOrEngine,
      };
      newStep = {
        ...newStep,
        tracks: newTracks,
      };
    }

    foundTrack = structuredClone(Object.values(newStep.tracks).find(x => x.simulator_id === currSimResultStep.location_id.toString()));

    const yardEngineIdKey = Object.keys(yardEngineIds).find(x => x === simresults.target_yard);
    const yardEngineId = yardEngineIds[yardEngineIdKey];
    foundEngine = structuredClone(Object.values(newStep.tracks).find(x => x.train_id === yardEngineId));


    addFoundTrackToAffectedTracks(foundTrack, stepsListWrapper);
    addFoundTrackToAffectedTracks(foundEngine, stepsListWrapper);

    let stepText = '';//`${i + 1}) `;
    if (currSimResultStep.action_type === 'pick_up') {

      //special rules for the text for the step
      if (currSimResultStep.pull_length === foundTrack.carIds.length) {
        //all cars
        stepText += `Pick up all cars (${currSimResultStep.pull_length}) from ${foundTrack.title}`;
        newStep.stepText = stepText;
      }
      else {
        //cut - find last car being taken and call the cut there
        stepText += `Pick up ${currSimResultStep.pull_length} car${currSimResultStep.pull_length !== 1 ? 's' : ''} from ${foundTrack.title}, cutting at `;
        //count backwards to find car being kept, and last car not kept
        stepText += `${foundTrack.carIds.slice(0 - currSimResultStep.pull_length)[0]}, leaving behind ${foundTrack.carIds.slice(-1 - currSimResultStep.pull_length)[0]}.`;
        newStep.stepText = stepText;
      }

      //we are removing cars from a track and adding to an engine
      const carsPicked = foundTrack.carIds.slice(foundTrack.carIds.length - currSimResultStep.pull_length);
      // console.log('carsPicked', carsPicked);

      carsPicked.forEach(carId => {
        let currCar = structuredClone(Object.values(newStep.cars).find(x => x.id === carId));
        currCar.isAddingToEngine = true;
        currStepCars = {
          ...currStepCars,
          [currCar.id]: currCar,
        }
      });

      foundEngine.carIds = carsPicked.concat(foundEngine.carIds);
      // console.log('foundEngine', foundEngine);
      // foundTrack.carIds = foundTrack.carIds.slice(0, foundTrack.carIds.length - carsPicked.length);
      prevRemovalTrackOrEngine = structuredClone(foundTrack);
      prevRemovalTrackOrEngine.carIds = prevRemovalTrackOrEngine.carIds.slice(0, prevRemovalTrackOrEngine.carIds.length - carsPicked.length);
    }
    else {
      //drop off

      //special rules for the text for the step
      if (currSimResultStep.pull_length === foundEngine.carIds.length) {
        //all cars
        stepText += `Drop all cars (${currSimResultStep.pull_length}) from Engine to ${foundTrack.title}`;
        newStep.stepText = stepText;
      }
      else {
        //cut - find last car being taken and call the cut there
        stepText += `Drop off ${currSimResultStep.pull_length} car${currSimResultStep.pull_length !== 1 ? 's' : ''} to ${foundTrack.title}, cutting at `;
        //count forwards to find car being kept, and last car not kept
        stepText += `${foundEngine.carIds[currSimResultStep.pull_length]}, leaving behind ${foundEngine.carIds[currSimResultStep.pull_length - 1]}.`;
        newStep.stepText = stepText;
      }


      //we are removing cars from an engine and adding to a track
      const carsDropped = foundEngine.carIds.slice(0, currSimResultStep.pull_length);
      // console.log('carsDropped', carsDropped);

      carsDropped.forEach(carId => {
        let currCar = structuredClone(Object.values(newStep.cars).find(x => x.id === carId));
        currCar.isRemovingFromEngine = true;
        currCar.isOnEngine = false;
        currStepCars = {
          ...currStepCars,
          [currCar.id]: currCar,
        }
      });

      foundTrack.carIds = foundTrack.carIds.concat(carsDropped);

      prevRemovalTrackOrEngine = structuredClone(foundEngine);
      prevRemovalTrackOrEngine.carIds = prevRemovalTrackOrEngine.carIds.slice(currSimResultStep.pull_length);
    }

    const newTracks = {
      ...newStep.tracks,
      [foundTrack.id]: foundTrack,
      [foundEngine.id]: foundEngine,
    };

    newStep = {
      ...newStep,
      cars: currStepCars,
      tracks: newTracks,
    };

    // console.log('newStep', newStep);

    // calculatedSteps[i + 1] = newStep;
    calculatedSteps.push(newStep);
  }

  addFinalConfiguration(calculatedSteps, foundEngine, foundTrack);

  // console.log('stepsListWrapper', stepsListWrapper);
  // return calculatedSteps;
  return stepsListWrapper;
}


function addFoundTrackToAffectedTracks(foundTrack, stepsListWrapper) {
  if (!stepsListWrapper.affectedTracks.includes(foundTrack.id)) {
    stepsListWrapper.affectedTracks.push(foundTrack.id);
    stepsListWrapper.affectedTracks.sort((a, b) => a < b);
  }
}




function clearPreviousStepsCarHighlighting(prevStepCars, currStepCars) {
  const previouslyMovedCars = Object.values(prevStepCars).filter(x => x.isAddingToEngine || x.isRemovingFromEngine);
  previouslyMovedCars.forEach(car => {
    //remove cars from tracks, where they would have been highlighted for removal
    let newCar = structuredClone(car);
    newCar.isAddingToEngine = false;
    newCar.isRemovingFromEngine = false;
    currStepCars = {
      ...currStepCars,
      [car.id]: newCar,
    };
  });

  return currStepCars;
}


function addFinalConfiguration(calculatedSteps, foundEngine, foundTrack) {
  //this displays the final configuration by removing the last cars moved from their source, and clears the highlighting
  let prevStep = calculatedSteps[calculatedSteps.length - 1];
  let newStep = {
    ...prevStep,
    id: prevStep.id + 1,
    stepText: 'Final Configuration',
  };

  const finalFoundEngine = structuredClone(foundEngine);
  const finalFoundTrack = structuredClone(foundTrack);

  let currStepCars = {
    ...prevStep.cars,
  };

  const previouslyAddingToEngineCars = Object.values(prevStep.cars).filter(x => x.isAddingToEngine);
  previouslyAddingToEngineCars.forEach(car => {

    //remove the car from the track, and set it to no longer adding to engine
    finalFoundTrack.carIds = finalFoundEngine.carIds.filter(x => x !== car.id);

    let newCar = structuredClone(car);
    newCar.isAddingToEngine = false;
    currStepCars = {
      ...currStepCars,
      [car.id]: newCar,
    };
  });

  const previouslyAddingToTrackCars = Object.values(prevStep.cars).filter(x => x.isRemovingFromEngine);
  previouslyAddingToTrackCars.forEach(car => {

    //remove the car from the engine, and set it to no longer adding to track
    finalFoundEngine.carIds = finalFoundEngine.carIds.filter(x => x !== car.id);

    let newCar = structuredClone(car);
    newCar.isRemovingFromEngine = false;
    currStepCars = {
      ...currStepCars,
      [car.id]: newCar,
    };
  });


  const newTracks = {
    ...newStep.tracks,
    [finalFoundTrack.id]: finalFoundTrack,
    [finalFoundEngine.id]: finalFoundEngine,
  };

  newStep = {
    ...newStep,
    cars: currStepCars,
    tracks: newTracks,
  };

  calculatedSteps.push(newStep);

  // return calculatedSteps;
}