import React from 'react';
import FileLoader from './fileloader';
import { useMsal } from '@azure/msal-react';

function FileLoaderWrapper(props) {
  const { instance } = useMsal();

  return (
    <FileLoader
      autoClick={props.autoClick}
      msalInstance={instance}
      fileLocation={props.fileLocation}
      fileFolder={props.fileFolder}
      fileName={props.fileName}
      onChange={props.onChange}
      isWaiting={props.isWaiting}
      resultStatus={props.resultStatus}
      ETA={props.ETA}
    >
    </FileLoader>
  )

}

export default FileLoaderWrapper;