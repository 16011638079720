import Api from "./Api";

export default async function fetchFileMetadata(filePrefix, msalInstance) {
  const resultsApiLocation = process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_FIND_FILES_SERVICE;

  let res = null;
  await Api.GET(resultsApiLocation,
    {
      filename: filePrefix,
    },
    data => {
      res = data.find(x => x.name === filePrefix); //ensure there's only one, as findfiles is really using a prefix
    },
    err => {
      console.log('error', err);
      alert('error! ' + err);
    },
    msalInstance
  );
  return res;
}

// module.exports = { fetchFileInfo };

