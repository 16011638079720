import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border: ${(props) =>
    props.$selectedSimResult === props.$simId
      ? "2px solid #2469BD"
      : "1px solid lightgrey"};
  border-radius: 2px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  font-size: 11px;
  background-color: white;
`;

const StagingTrackInfo = styled.div`
  background-color: #d6e9ff;
`;

export default class SimulationResultCard extends React.PureComponent {
  calculateTotalCarsSpotted(resultInfo) {
    if (!resultInfo) {
      return null;
    }
    // console.log(resultInfo);
  }

  render() {
    return (
      <Container
        onClick={(e) =>
          this.props.selectSimResult(this.props.resultInfo?.Simulation)
        }
        $simId={this.props.resultInfo?.Simulation}
        $selectedSimResult={this.props.selectedSimResult}
      >
        <div style={{ fontSize: "70%" }}>
          Simulation#: {this.props.resultInfo?.Simulation}
        </div>
        <div>Spotted Cars: {this.props?.spottedCarCount ?? 0}</div>
        <div>Total Moves: {this.props.resultInfo?.total_moves}</div>
        <div>
          Yard Organization: {Math.round(this.props.resultInfo?.yard_org * 100)}
          %
        </div>
        {this.props.resultInfo?.intermediate_track ? (
          <StagingTrackInfo>
            Staging Track: {this.props.resultInfo?.intermediate_track}{" "}
          </StagingTrackInfo>
        ) : null}
      </Container>
    );
  }
}
