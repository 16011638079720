import React from 'react';
import SimRequest from "./sim-request";
import { useMsal } from '@azure/msal-react';


function SimRequestWrapper(props) {
  const msalInstance = useMsal();

  return (
    <SimRequest
      msalInstance={msalInstance.instance}
      yards={props.yards}
      yardNames={Object.keys(props.yards)}
      trackOrder={props.trackOrder}
      cars={props.cars}
      tracks={props.tracks}
      racks={props.racks}
      disabledTracks={props.disabledTracks}
      disabledBays={props.disabledBays}
      onSimRequestFound={props.onSimRequestFound}
      latestSimRequestDate={props.latestSimRequestDate}
      onSimRequestComplete={props.onSimRequestComplete}
      disabledTracksChanged={props.disabledTracksChanged}
      disabledBaysChanged={props.disabledBaysChanged}
      simulatorRunning={props.simulatorRunning}
      isSimulatorRunning={props.isSimulatorRunning}
    ></SimRequest>
  );
}

export default SimRequestWrapper;