
function date2str(date, formatString) {
  var z = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds()
  };
  let curr = formatString.replace(/(y+)/g, z.y);
  curr = curr.replace(/M+/g, z.M.toString().padStart(2, '0'));
  curr = curr.replace(/d+/g, z.d.toString().padStart(2, '0'));
  curr = curr.replace(/h+/g, z.h.toString().padStart(2, '0'));
  curr = curr.replace(/m+/g, z.m.toString().padStart(2, '0'));
  curr = curr.replace(/s+/g, z.s.toString().padStart(2, '0'));
  return curr;
}

function convertFromYYYYMMDDHHMMSS(origStr) {
  return new Date(origStr.slice(0, 4), origStr.slice(4, 6) - 1, origStr.slice(6, 8),
    origStr.slice(8, 10), origStr.slice(10, 12), origStr.slice(12, 14));
}

module.exports = { date2str, convertFromYYYYMMDDHHMMSS };